// import React, { useState, useEffect } from 'react';
// import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { clearUser, setProfile, setDisplayChat, setNewSupportMessage, setDisplaySupportChat } from '../../Redux/store';
// import api from '../../api/axiosConfig';
// import { FaFirstOrder, FaMailBulk, FaSearch, FaSpinner, FaUser } from 'react-icons/fa';

// function TutorSidebar() {
//   const location = useLocation();
//   const user = useSelector(state => state.user);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [profileData, setProfileData] = useState(null);
//   const [menuOpen, setMenuOpen] = useState(false);
//   const profile = useSelector(state => state.profile);

//   const getHeading = () => {
//     const pathToHeading = {
//       '/tutor/inbox': 'Inbox',
//       '/tutor/works': 'Orders',
//       '/tutor/chatx': 'Chat',
//       '/tutor/profile': 'My Profile'
//     };
//     return pathToHeading[location.pathname] || 'My Dashboard';
//   };

//   const heading = getHeading();

//   useEffect(() => {
//     if (profile) {
//       setProfileData(profile);
//     } else {
//       api.get('/tutor/tutor-profile/')
//         .then(response => {
//           const p = response.data;
//           dispatch(setProfile(p));
//           setProfileData(p);
//         })
//         .catch(error => {
//           console.error('Error fetching profile data:', error);
//           toast.error('Failed to fetch profile data'); // Added error notification
//         });
//     }
//   }, [profile, dispatch]); // Added dependencies

//   const handleLogout = () => {
//     // TODO: Add API call to invalidate session on the server
//     dispatch(clearUser());
//     // TODO: Clear any stored tokens
//     toast.success('Tutor Logged out');
//     navigate('/login');
//   };

//   const openChat = () => {
//     if (window.innerWidth < 1024) {
//       setMenuOpen(false);
//     }
//     dispatch(setDisplayChat('block'));
//   };

//   const openSupportChat = () => {
//     if (window.innerWidth < 1024) {
//       setMenuOpen(false);
//     }
//     const orderMessage = {
//       order_number: 'SUPPORT'
//     };
//     dispatch(setNewSupportMessage(orderMessage));
//     dispatch(setDisplaySupportChat('block'));
//   };

//   // Extracted repeated NavLink styling logic
//   const getNavLinkClass = (path) => {
//     const baseClass = "px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group";
//     return `${baseClass} ${location.pathname === path ? 'bg-black' : 'bg-transparent'}`;
//   };

//   const getNavLinkTextClass = (path) => {
//     return location.pathname === path ? 'font-bold text-white' : '-mr-1 font-medium';
//   };

//   return (
//     <>
//       <div className="sticky z-10 top-0 h-16 border-b bg-white lg:py-2.5">
//         <div className="px-6 flex items-center justify-between space-x-4 2xl:container">
//           <h2 className="text-2xl text-black font-bold lg:ml-72 lg:mt-2">{heading}</h2>
//           <button
//             className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-md lg:hidden"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-6 w-6"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M4 6h16M4 12h16M4 18h16"
//               />
//             </svg>
//             <span className="font-medium">MENU</span>
//           </button>
//         </div>
//       </div>
//       <aside className={`fixed z-20 top-0 pb-3 px-4 w-full flex flex-col justify-between h-screen border-r transition duration-300 md:w-3/12 lg:ml-0 lg:w-[20%] xl:w-[15%] 2xl:w-[10%] ${menuOpen ? 'left-0' : 'lg:left-0 -left-full'}`}
//         style={{ boxShadow: '0 0 10px rgba(0,0,0,0.1)', background: 'linear-gradient(to bottom ,#AD9551 , goldenrod)' }}>
//         <button onClick={() => setMenuOpen(false)} className="absolute top-2 right-2 text-red-800 focus:outline-none hover:text-red-600 lg:hidden">
//           <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
//           </svg>
//         </button>
//         <div>
//           <div className="-mx-6 px-6 py-4">
//             <Link to="/tutor" title="home">
//               <div className="flex items-center justify-center">
//                 <h1 className="header-logo">WriterBeaz</h1>
//               </div>
//             </Link>
//           </div>
//           {profileData && (
//             <div className="mt-8 text-center">
//               <Link to='/tutor/profile'>
//                 <img
//                   src={profileData.profile_photo}
//                   alt="Tutor Profile"
//                   className="w-10 h-10 m-auto rounded-full object-cover lg:w-28 lg:h-28"
//                 />
//               </Link>
//               <h6 className="hidden mt-3 text-base font-bold text-gray-700 lg:block">
//                 {profileData.tutor.username} | Tutor
//               </h6>
//               <Link className='font-bold text-xs text-gray-700 hover:text-gray-600 md:ml-2' to='/tutor/profile'>view profile</Link>
//             </div>
//           )}
//           <ul className="space-y- tracking-wide mt-8">
//             <li>
//               <NavLink
//                 to="/tutor"
//                 aria-label="dashboard"
//                 className={getNavLinkClass('/tutor')}
//               >
//                 <FaUser />
//                 <span className={getNavLinkTextClass('/tutor')}>{user.username}</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/tutor/inbox"
//                 className={getNavLinkClass('/tutor/inbox')}
//               >
//                 <FaMailBulk />
//                 <span className={getNavLinkTextClass('/tutor/inbox')}>Inbox</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/tutor/works"
//                 className={getNavLinkClass('/tutor/works')}
//               >
//                 <FaFirstOrder />
//                 <span className={getNavLinkTextClass('/tutor/works')}>Orders</span>
//               </NavLink>
//             </li>
//             <li>
//               <button
//                 onClick={openChat}
//                 className={getNavLinkClass('/tutor/chatx')}
//                 aria-label="Open chat"
//               >
//                 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
//                   <path className="fill-current text-gray-600 group-hover:text-cyan-600" d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
//                   <path className="fill-current text-gray-300 group-hover:text-cyan-300" d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
//                 </svg>
//                 <span className={getNavLinkTextClass('/tutor/chatx')}>Chat</span>
//               </button>
//             </li>
//             <li>
//               <NavLink
//                 to="/tutor/find-job"
//                 className={getNavLinkClass('/tutor/find-job')}
//               >
//                 <FaSearch />
//                 <span className={getNavLinkTextClass('/tutor/find-job')}>Find a Job</span>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/tutor/bids"
//                 className={getNavLinkClass('/tutor/bids')}
//               >
//                 <FaSpinner />
//                 <span className={getNavLinkTextClass('/tutor/bids')}>Bids</span>
//               </NavLink>
//             </li>
//             <li>
//               <button
//                 onClick={openSupportChat}
//                 className="px-4 py-3 flex items-center space-x-4 rounded-md text-white group bg-black mt-4 mx-auto"
//               >
//                 <span>Chat Support</span>
//               </button>
//             </li>
//           </ul>
//         </div>

//         <div className="px-6 -mx-6 pt-4 flex justify-between items-center border-t">
//           <button
//             onClick={handleLogout}
//             className="px-4 py-3 flex items-center space-x-2 rounded-md text-black group font-semibold hover:text-white hover:bg-black"
//           >
//             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
//             </svg>
//             <span>Logout</span>
//           </button>
//         </div>
//       </aside>
//       {!menuOpen && (
//         <div className="fixed top-20 left-16 bg-black text-white p-2 rounded-md shadow-lg lg:hidden">
//           <p className="text-sm">Click MENU to navigate</p>
//         </div>
//       )}
//     </>
//   );
// }

// export default TutorSidebar;




import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearUser, setProfile, setDisplayChat, setNewSupportMessage, setDisplaySupportChat } from '../../Redux/store';
import api from '../../api/axiosConfig';
import { FaFirstOrder, FaMailBulk, FaSearch, FaSpinner, FaUser } from 'react-icons/fa';
import ChatPage from '../Chat/ChatPage';
import SupportChatPage from '../Chat/SupportChat';

function TutorSidebar() {
  const location = useLocation();
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const profile = useSelector(state => state.profile);
  const displayChat = useSelector((state) => state.displayChat);
  const displaySupportChat = useSelector((state) => state.displaySupportChat);

  const getHeading = () => {
    const pathToHeading = {
      '/tutor/inbox': 'Inbox',
      '/tutor/works': 'Orders',
      '/tutor/chatx': 'Chat',
      '/tutor/profile': 'My Profile'
    };
    return pathToHeading[location.pathname] || 'My Dashboard';
  };

  const heading = getHeading();

  useEffect(() => {
    if (profile) {
      setProfileData(profile);
    } else {
      api.get('/tutor/tutor-profile/')
        .then(response => {
          const p = response.data;
          dispatch(setProfile(p));
          setProfileData(p);
        })
        .catch(error => {
          console.error('Error fetching profile data:', error);
          toast.error('Failed to fetch profile data'); // Added error notification
        });
    }
  }, [profile, dispatch]); // Added dependencies

  const handleLogout = () => {
    // TODO: Add API call to invalidate session on the server
    dispatch(clearUser());
    // TODO: Clear any stored tokens
    toast.success('Tutor Logged out');
    navigate('/login');
  };

  const openChat = () => {
    if (window.innerWidth < 1024) {
      setMenuOpen(false);
    }
    dispatch(setDisplayChat('block'));
  };

  const openSupportChat = () => {
    if (window.innerWidth < 1024) {
      setMenuOpen(false);
    }
    const orderMessage = {
      order_number: 'SUPPORT'
    };
    dispatch(setNewSupportMessage(orderMessage));
    dispatch(setDisplaySupportChat('block'));
  };

  // Extracted repeated NavLink styling logic
  const getNavLinkClass = (path) => {
    const baseClass = "px-4 py-3 flex items-center space-x-4 rounded-md text-gray-600 group";
    return `${baseClass} ${location.pathname === path ? 'bg-black' : 'bg-transparent'}`;
  };

  const getNavLinkTextClass = (path) => {
    return location.pathname === path ? 'font-bold text-white' : '-mr-1 font-medium';
  };

  return (
    <>
      <div className="sticky z-10 top-0 h-16 border-b bg-white lg:py-2.5">
        <div className="px-6 flex items-center justify-between space-x-4 2xl:container">
          <h2 className="text-2xl text-black font-bold lg:ml-72 lg:mt-2">{heading}</h2>
          <button
            className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-md lg:hidden"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <span className="font-medium">MENU</span>
          </button>
        </div>
      </div>
      <aside className={`fixed z-20 top-0 pb-3 px-4 w-full flex flex-col justify-between h-screen border-r transition duration-300 md:w-3/12 lg:ml-0 lg:w-[20%] xl:w-[15%] 2xl:w-[10%] ${menuOpen ? 'left-0' : 'lg:left-0 -left-full'}`}
        style={{ boxShadow: '0 0 10px rgba(0,0,0,0.1)', background: 'linear-gradient(to bottom ,#AD9551 , goldenrod)' }}>
        <button onClick={() => setMenuOpen(false)} className="absolute top-2 right-2 text-red-800 focus:outline-none hover:text-red-600 lg:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
        <div>
          <div className="-mx-6 px-6 py-4">
            <Link to="/tutor" title="home">
              <div className="flex items-center justify-center">
                <h1 className="header-logo">WriterBeaz</h1>
              </div>
            </Link>
          </div>
          {profileData && (
            <div className="mt-8 text-center">
              <Link to='/tutor/profile'>
                <img
                  src={profileData.profile_photo}
                  alt="Tutor Profile"
                  className="w-10 h-10 m-auto rounded-full object-cover lg:w-28 lg:h-28"
                />
              </Link>
              <h6 className="hidden mt-3 text-base font-bold text-gray-700 lg:block">
                {profileData.tutor.username} | Tutor
              </h6>
              <Link className='font-bold text-xs text-gray-700 hover:text-gray-600 md:ml-2' to='/tutor/profile'>view profile</Link>
            </div>
          )}
          <ul className="space-y- tracking-wide mt-8">
            <li>
              <NavLink
                to="/tutor"
                aria-label="dashboard"
                className={getNavLinkClass('/tutor')}
              >
                <FaUser />
                <span className={getNavLinkTextClass('/tutor')}>{user.username}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/tutor/inbox"
                className={getNavLinkClass('/tutor/inbox')}
              >
                <FaMailBulk />
                <span className={getNavLinkTextClass('/tutor/inbox')}>Inbox</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/tutor/works"
                className={getNavLinkClass('/tutor/works')}
              >
                <FaFirstOrder />
                <span className={getNavLinkTextClass('/tutor/works')}>Orders</span>
              </NavLink>
            </li>
            <li>
              <button
                onClick={openChat}
                className={getNavLinkClass('/tutor/chatx')}
                aria-label="Open chat"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path className="fill-current text-gray-600 group-hover:text-cyan-600" d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                  <path className="fill-current text-gray-300 group-hover:text-cyan-300" d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                </svg>
                <span className={getNavLinkTextClass('/tutor/chatx')}>Chat</span>
              </button>
            </li>
            <li>
              <NavLink
                to="/tutor/find-job"
                className={getNavLinkClass('/tutor/find-job')}
              >
                <FaSearch />
                <span className={getNavLinkTextClass('/tutor/find-job')}>Find a Job</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/tutor/bids"
                className={getNavLinkClass('/tutor/bids')}
              >
                <FaSpinner />
                <span className={getNavLinkTextClass('/tutor/bids')}>Bids</span>
              </NavLink>
            </li>
            <li>
              <button
                onClick={openSupportChat}
                className="px-4 py-3 flex items-center space-x-4 rounded-md text-white group bg-black mt-4 mx-auto"
              >
                <span>Chat Support</span>
              </button>
            </li>
          </ul>
        </div>

        <div className="px-6 -mx-6 pt-4 flex justify-between items-center border-t">
          <button
            onClick={handleLogout}
            className="px-4 py-3 flex items-center space-x-2 rounded-md text-black group font-semibold hover:text-white hover:bg-black"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>Logout</span>
          </button>
        </div>
      </aside>
      {!menuOpen && (
        <div className="fixed top-20 left-16 bg-black text-white p-2 rounded-md shadow-lg lg:hidden">
          <p className="text-sm">Click MENU to navigate</p>
        </div>
      )}
      {displayChat === 'block' && <ChatPage position="right" />}
      {displaySupportChat === 'block' && <SupportChatPage position="right" />}
    </>
  );
}

export default TutorSidebar;
