// import React from 'react';
// import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

// const StarRating = ({ rating }) => {
//   const stars = [];
//   const fullStars = Math.floor(rating);
//   const hasHalfStar = rating % 1 >= 0.5;

//   for (let i = 1; i <= 5; i++) {
//     if (i <= fullStars) {
//       stars.push(<FaStar key={i} className="text-yellow-400" />);
//     } else if (i === fullStars + 1 && hasHalfStar) {
//       stars.push(<FaStarHalfAlt key={i} className="text-yellow-400" />);
//     } else {
//       stars.push(<FaStar key={i} className="text-gray-300" />);
//     }
//   }

//   return <div className="flex">{stars}</div>;
// };

// export default StarRating;


import React from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

const StarRating = ({ rating, size = 20 }) => {
  const stars = [];
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;

  for (let i = 1; i <= 5; i++) {
    if (i <= fullStars) {
      stars.push(<FaStar key={i} className="text-yellow-400" size={size} />);
    } else if (i === fullStars + 1 && hasHalfStar) {
      stars.push(<FaStarHalfAlt key={i} className="text-yellow-400" size={size} />);
    } else {
      stars.push(<FaStar key={i} className="text-gray-300" size={size} />);
    }
  }

  return <div className="flex">{stars}</div>;
};

export default StarRating;
