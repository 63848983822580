// import React, { useState, useEffect } from 'react';
// import TutorSidebar from '../Layout/TutorSidebar';
// import { useSelector } from 'react-redux';
// import api from '../../api/axiosConfig';

// function Bids() {
//   const [tutorBids, setTutorBids] = useState([]);
//   const user = useSelector(state => state.user);
//   const [editting, setEditting] = useState(false);
//   const [editId, setEditId] = useState(null);
//   const [editPrice, setEditPrice] = useState('');
//   const [editMessage, setEditMessage] = useState('');

//   useEffect(() => {
//     fetchTutorBids();
//   }, [user.username]);


//   const fetchTutorBids = async () => {
//     try {
//       // Fetch all proposals from the server
//       const response = await api.get('/tutor/proposal/');

//       // Filter proposals for the tutor based on their username
//       const bidsForTutor = response.data.filter(proposal => proposal.username === user.username);

//       // Sort the bids by order number with the latest on top
//       const sortedBids = bidsForTutor.sort((a, b) => b.id - a.id);

//       // Update state with the sorted bids
//       setTutorBids(sortedBids);
//     } catch (error) {
//       console.error('Error fetching tutor bids:', error);
//     }
//   };

//   const updateBid = async (bidId, price, message) => {
//     price = parseInt(price);
//     console.log(bidId, price, message)
//     try {
//       await api.put(`/tutor/proposal/update/${bidId}/`, { price, proposal: message });
//       fetchTutorBids();
//     } catch (error) {
//       console.error('Error updating bid:', error);
//     }
//   }

//   return (
//     <div>
//       <TutorSidebar />
//       <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
//         <h1 className="text-3xl font-bold mb-4">Your Bids</h1>
//         {/* {tutorBids.length > 0 ? (
//           <table className="w-full border border-gray-300">
//             <thead>
//               <tr className="bg-gray-200">
//                 {Object.keys(tutorBids[0]).map((key) => (
//                   <th key={key} className="py-2 px-4 border-r">
//                     {key.replace(/([a-z])([A-Z])/g, '$1 $2')}
//                   </th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {tutorBids.map((bid, index) => (
//                 <tr key={index} className="border-b border-gray-300">
//                   {Object.values(bid).map((value, index) => (
//                     <td key={index} className="py-2 font-bold px-4 border-r">
//                       {value.toString()}
//                     </td>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           <p>No bids submitted by you.</p>
//         )} */}

//         {/* {
//         "id": 52,
//         "job_id": 91,
//         "price": 10,
//         "proposal": "It will reload on order submission",
//         "username": "skye",
//         "isAccepted": false,
//         "proposalDate": "2024-01-24",
//         "viewed": true
//     }, */}

//         {tutorBids.length > 0 ? (
//           tutorBids.map((bid, index) => (
//             <div key={index} className="border p-4 mb-4 w-96">
//               <h2 className="text-xl font-bold">Job ID: {bid.job_id}</h2>
//               {editting && editId === bid.id ?
//                 (<textarea value={editMessage} onChange={(e) => setEditMessage(e.target.value)} className="border border-gray-300 rounded-lg px-2 py-1 focus:outline-none mb-2" rows={4}>
//                 </textarea>) :
//                 <p>{bid.proposal}</p>}
//               <p>Submitted on: {bid.proposalDate}</p>
//               <b>Price</b>
//               {editting && editId === bid.id ?
//                 (<input
//                   type="number"
//                   value={editPrice}
//                   onChange={(e) => setEditPrice(e.target.value)}
//                   className="border border-gray-300 rounded-lg px-2 py-1 focus:outline-none mb-2"
//                 />) : (
//                   <p>{bid.price}</p>

//                 )}
//               {bid.isAccepted ? <p className="text-sm text-white bg-green-500 px-1 w-fit rounded">Accepted</p> : <p className="text-sm text-white bg-red-500 p-1 w-fit rounded">Not Accepted</p>}
//               {!editting && (
//                 <button onClick={() => {
//                   setEditting(true);
//                   setEditId(bid.id);
//                   setEditPrice(bid.price);
//                   setEditMessage(bid.proposal);
//                 }
//                 } className="mt-4 bg-blue-500 text-white px-2 py-1 rounded-lg">Edit</button>
//               )}
//               {editting && editId === bid.id && (
//                 <button onClick={() => {
//                   updateBid(editId, editPrice, editMessage);
//                   setEditting(false);
//                 }} className="mt-4 bg-green-500 text-white px-2 py-1 rounded-lg">Save</button>
//               )}
//             </div>
//           ))
//         ) : (
//           <p>No bids submitted by you.</p>
//         )}

//       </div>
//     </div>
//   );
// }

// export default Bids;


import React, { useState, useEffect } from 'react';
import TutorSidebar from '../Layout/TutorSidebar';
import { useSelector } from 'react-redux';
import api from '../../api/axiosConfig';

function Bids() {
  const [tutorBids, setTutorBids] = useState([]);
  const user = useSelector(state => state.user);
  const [editting, setEditting] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editPrice, setEditPrice] = useState('');
  const [editMessage, setEditMessage] = useState('');

  useEffect(() => {
    fetchTutorBids();
  }, [user.username]);


  const fetchTutorBids = async () => {
    try {
      // Fetch all proposals from the server
      const response = await api.get('/tutor/proposal/');

      // Filter proposals for the tutor based on their username
      const bidsForTutor = response.data.filter(proposal => proposal.username === user.username);

      // Sort the bids by order number with the latest on top
      const sortedBids = bidsForTutor.sort((a, b) => b.id - a.id);

      // Update state with the sorted bids
      setTutorBids(sortedBids);
    } catch (error) {
      console.error('Error fetching tutor bids:', error);
    }
  };

  const updateBid = async (bidId, price, message) => {
    price = parseInt(price);
    console.log(bidId, price, message)
    try {
      await api.put(`/tutor/proposal/update/${bidId}/`, { price, proposal: message });
      fetchTutorBids();
    } catch (error) {
      console.error('Error updating bid:', error);
    }
  }

  return (
    <div>
      <TutorSidebar />
      <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
        <h1 className="text-3xl font-bold mb-4">Your Bids</h1>
        {/* {tutorBids.length > 0 ? (
          <table className="w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                {Object.keys(tutorBids[0]).map((key) => (
                  <th key={key} className="py-2 px-4 border-r">
                    {key.replace(/([a-z])([A-Z])/g, '$1 $2')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tutorBids.map((bid, index) => (
                <tr key={index} className="border-b border-gray-300">
                  {Object.values(bid).map((value, index) => (
                    <td key={index} className="py-2 font-bold px-4 border-r">
                      {value.toString()}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No bids submitted by you.</p>
        )} */}

        {/* {
        "id": 52,
        "job_id": 91,
        "price": 10,
        "proposal": "It will reload on order submission",
        "username": "skye",
        "isAccepted": false,
        "proposalDate": "2024-01-24",
        "viewed": true
    }, */}

        {tutorBids.length > 0 ? (
          tutorBids.map((bid, index) => (
            <div key={index} className="border p-4 mb-4 w-96">
              <h2 className="text-xl font-bold">Job ID: {bid.job_id}</h2>
              {editting && editId === bid.id ?
                (<textarea value={editMessage} onChange={(e) => setEditMessage(e.target.value)} className="border border-gray-300 rounded-lg px-2 py-1 focus:outline-none mb-2" rows={4}>
                </textarea>) :
                <p>{bid.proposal}</p>}
              <p>Submitted on: {bid.proposalDate}</p>
              <b>Price</b>
              {editting && editId === bid.id ?
                (<input
                  type="number"
                  value={editPrice}
                  onChange={(e) => setEditPrice(e.target.value)}
                  className="border border-gray-300 rounded-lg px-2 py-1 focus:outline-none mb-2"
                />) : (
                  <p>{bid.price}</p>
                )}
              <p>Status: {bid.isAccepted ? <span className="text-sm text-white bg-green-500 px-1 w-fit rounded">Accepted</span> : <span className="text-sm text-white bg-red-500 p-1 w-fit rounded">Not Accepted</span>}</p>
              <p>Viewed: {bid.viewed ? <span className="text-sm text-white bg-green-500 px-1 w-fit rounded">Yes</span> : <span className="text-sm text-white bg-red-500 p-1 w-fit rounded">No</span>}</p>
              {!editting && (
                <button onClick={() => {
                  setEditting(true);
                  setEditId(bid.id);
                  setEditPrice(bid.price);
                  setEditMessage(bid.proposal);
                }
                } className="mt-4 bg-blue-500 text-white px-2 py-1 rounded-lg">Edit</button>
              )}
              {editting && editId === bid.id && (
                <button onClick={() => {
                  updateBid(editId, editPrice, editMessage);
                  setEditting(false);
                }} className="mt-4 bg-green-500 text-white px-2 py-1 rounded-lg">Save</button>
              )}
            </div>
          ))
        ) : (
          <p>No bids submitted by you.</p>
        )}

      </div>
    </div>
  );
}

export default Bids;
