import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import UserSidebar from './UserBoard/UserSidebar';

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function UnsubscribePage() {
  console.log("UnsubscribePage component rendered");
  const [preferences, setPreferences] = useState({
    promotions: true,
    events: true,
    offers: true,
  });
  const user = useSelector(state => state.user);
  console.log("User state:", user);

  useEffect(() => {
    console.log("useEffect triggered, user:", user);
    if (user && user.user_id) {
      console.log("Fetching preferences for user:", user.user_id);
      fetchPreferences();
    } else {
      console.log("User not logged in or user_id not available");
    }
  }, [user]);

  const fetchPreferences = async () => {
    try {
      const response = await api.get('/users/unsubscribe-preferences/');
      setPreferences(response.data);
    } catch (error) {
      console.error('Error fetching preferences:', error);
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setPreferences(prev => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const csrftoken = getCookie('csrftoken');
      await api.put('/users/unsubscribe-preferences/', preferences, {
        headers: {
          'X-CSRFToken': csrftoken
        }
      });
      toast.success('Preferences updated successfully');
    } catch (error) {
      console.error('Error updating preferences:', error);
      toast.error('Failed to update preferences');
    }
  };

  if (!user) {
    return <div>Please log in to manage your subscription preferences.</div>;
  }

  return (
    <div className="flex">
      <UserSidebar />
      <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%] mt-20">
        <div className="container mx-auto mt-10">
          <h1 className="text-2xl font-bold mb-5">Manage Your Subscription Preferences</h1>
          <form onSubmit={handleSubmit} className="space-y-4">
            {Object.entries(preferences).map(([key, value]) => (
              <div key={key} className="flex items-center">
                <input
                  type="checkbox"
                  id={key}
                  name={key}
                  checked={value}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label htmlFor={key} className="capitalize">{key}</label>
              </div>
            ))}
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
              Update Preferences
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UnsubscribePage;
