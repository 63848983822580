// import React, { useState, useEffect } from 'react';
// import Navbar from '../Layout/Navbar';
// import api from '../../api/axiosConfig';
// import { useParams } from 'react-router-dom';
// import UserSidebar from './UserBoard/UserSidebar';
// import StarRating from './StarRating';

// function TutorView(props) {
//   const { id } = useParams();
//   const [profileData, setProfileData] = useState({});
//   const [skillData, setSkillData] = useState({});
//   const [feedbackData, setFeedbackData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [feedbackPerPage] = useState(10);
//   const [sortedFeedbackData, setSortedFeedbackData] = useState([]);
//   const [averageRating, setAverageRating] = useState(0);

//   useEffect(() => {
//     api.get(`/users/user-tutor/${id}/`)
//       .then((response) => {
//         setProfileData(response.data.profile);
//         setSkillData(response.data.skills);
//       })
//       .catch((error) => {
//         console.error(error);
//       });

//     // Fetch feedback data
//     api.get(`/users/tutor-feedback/${id}/`)
//       .then((response) => {
//         const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
//         setFeedbackData(sortedData);
//         setSortedFeedbackData(sortedData);
//       })
//       .catch((error) => {
//         console.error(error);
//       });

//     api.get(`/users/tutor-average-rating/${id}/`)
//       .then((response) => {
//         setAverageRating(response.data.average_rating);
//       })
//       .catch((error) => {
//         console.error('Error fetching average rating:', error);
//       });
//   }, [id]);

//   // Get current feedback
//   const indexOfLastFeedback = currentPage * feedbackPerPage;
//   const indexOfFirstFeedback = indexOfLastFeedback - feedbackPerPage;
//   const currentFeedback = sortedFeedbackData.slice(indexOfFirstFeedback, indexOfLastFeedback);

//   // Change page
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const Pagination = ({ feedbackPerPage, totalFeedback, paginate, currentPage }) => {
//     const totalPages = Math.ceil(totalFeedback / feedbackPerPage);

//     return (
//       <nav className="flex justify-center mt-4">
//         <ul className='flex space-x-4'>
//           <li>
//             <button
//               onClick={() => paginate(currentPage - 1)}
//               disabled={currentPage === 1}
//               className={`px-4 py-2 rounded ${currentPage === 1 ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
//             >
//               Previous Page
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={() => paginate(currentPage + 1)}
//               disabled={currentPage === totalPages}
//               className={`px-4 py-2 rounded ${currentPage === totalPages ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
//             >
//               Next Page
//             </button>
//           </li>
//         </ul>
//       </nav>
//     );
//   };

//   return (
//     <div className="flex">
//       <UserSidebar />
//       <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%] mt-20">
//         <div className="bg-white">
//           {profileData && (
//             <div className="container mx-auto py-8">
//               <div className="grid grid-cols-4 sm:grid-cols-12 gap-6 px-4">
//                 <div className="col-span-4 sm:col-span-3">
//                   <div className="bg-white shadow-xl rounded-lg p-6">
//                     <div className="flex flex-col items-center">
//                       <img
//                         src={profileData.profile_photo}
//                         className="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0"
//                         alt="Tutor"
//                       />
//                       {profileData.tutor && (
//                         <>
//                           <h1 className="text-xl font-bold">{profileData.tutor.username}</h1>
//                           <p className="text-gray-600">{profileData.title}</p>
//                           <p className={profileData.tutor.is_active ? "text-green-500" : "text-red-500"}>
//                             {profileData.tutor.is_active ? "Active" : "Not Active"}
//                           </p>
//                           <p className="text-gray-600"><span className="font-bold">Average Rating:</span> <span className="font-bold">{averageRating}</span> / 5</p>
//                           <StarRating rating={averageRating} size={24} />
//                         </>
//                       )}
//                     </div>
//                     <hr className="my-6 border-t border-gray-300" />
//                     <div className="flex flex-col">
//                       <span className="text-gray-800 uppercase font-bold tracking-wider mb-2">
//                         Skills
//                       </span>
//                       <ul>
//                         {Array.isArray(skillData) && skillData.map((skill) => (
//                           <li key={skill.id} className="mb-2">
//                             {skill.skill}
//                           </li>
//                         ))}
//                       </ul>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-span-4 sm:col-span-9">
//                   <div className="bg-white shadow-xl rounded-lg p-6">
//                     <h2 className="text-xl font-bold mb-4">About Tutor</h2>
//                     <p className="text-gray-700">
//                       {profileData.about || ''}
//                     </p>
//                   </div>
//                   <div className="bg-white shadow-xl rounded-lg p-6 mt-4">
//                     <h2 className="text-xl font-bold mb-4">Client Reviews & Ratings</h2>
//                     {currentFeedback.length > 0 ? (
//                       <>
//                         <ul>
//                           {currentFeedback.map((feedback, index) => (
//                             <li key={index} className="mb-4 border-b pb-4">
//                               <div className="flex items-center mb-2">
//                                 <span className="mr-2 font-semibold">Rating:</span>
//                                 {[...Array(5)].map((star, i) => (
//                                   <svg
//                                     key={i}
//                                     className={`w-5 h-5 ${i < feedback.rating ? 'text-yellow-400' : 'text-gray-300'}`}
//                                     fill="currentColor"
//                                     viewBox="0 0 20 20"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                   >
//                                     <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
//                                   </svg>
//                                 ))}
//                               </div>
//                               <p className="text-gray-600"><span className="font-semibold">Comment:</span> {feedback.comment}</p>
//                               <p className="text-gray-500 text-sm">
//                                 {feedback.created_at && `Submitted on ${new Date(feedback.created_at).toLocaleDateString()}`}
//                               </p>
//                             </li>
//                           ))}
//                         </ul>
//                         <Pagination
//                           feedbackPerPage={feedbackPerPage}
//                           totalFeedback={sortedFeedbackData.length}
//                           paginate={paginate}
//                           currentPage={currentPage}
//                         />
//                       </>
//                     ) : (
//                       <p className="text-gray-500">No feedback available.</p>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default TutorView;




import React, { useState, useEffect } from 'react';
import Navbar from '../Layout/Navbar';
import api from '../../api/axiosConfig';
import { useParams } from 'react-router-dom';
import UserSidebar from './UserBoard/UserSidebar';
import StarRating from './StarRating';

function TutorView(props) {
  const { id } = useParams();
  const [profileData, setProfileData] = useState({});
  const [skillData, setSkillData] = useState({});
  const [feedbackData, setFeedbackData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [feedbackPerPage] = useState(10);
  const [sortedFeedbackData, setSortedFeedbackData] = useState([]);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    api.get(`/users/user-tutor/${id}/`)
      .then((response) => {
        setProfileData(response.data.profile);
        setSkillData(response.data.skills);
      })
      .catch((error) => {
        console.error(error);
      });

    // Fetch feedback data
    api.get(`/users/tutor-feedback/${id}/`)
      .then((response) => {
        const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setFeedbackData(sortedData);
        setSortedFeedbackData(sortedData);
      })
      .catch((error) => {
        console.error(error);
      });

    api.get(`/users/tutor-average-rating/${id}/`)
      .then((response) => {
        setAverageRating(response.data.average_rating);
      })
      .catch((error) => {
        console.error('Error fetching average rating:', error);
      });
  }, [id]);

  // Get current feedback
  const indexOfLastFeedback = currentPage * feedbackPerPage;
  const indexOfFirstFeedback = indexOfLastFeedback - feedbackPerPage;
  const currentFeedback = sortedFeedbackData.slice(indexOfFirstFeedback, indexOfLastFeedback);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const Pagination = ({ feedbackPerPage, totalFeedback, paginate, currentPage }) => {
    const totalPages = Math.ceil(totalFeedback / feedbackPerPage);

    return (
      <nav className="flex justify-center mt-4">
        <ul className='flex space-x-4'>
          <li>
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded ${currentPage === 1 ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              Previous Page
            </button>
          </li>
          <li>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded ${currentPage === totalPages ? 'bg-gray-300 text-gray-500' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              Next Page
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="flex">
      <UserSidebar />
      <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%] mt-20">
        <div className="bg-white">
          {profileData && (
            <div className="container mx-auto py-8">
              <div className="grid grid-cols-4 sm:grid-cols-12 gap-6 px-4">
                <div className="col-span-4 sm:col-span-3">
                  <div className="bg-white shadow-xl rounded-lg p-6">
                    <div className="flex flex-col items-center">
                      <img
                        src={profileData.profile_photo}
                        className="w-32 h-32 bg-gray-300 rounded-full mb-4 shrink-0"
                        alt="Tutor"
                      />
                      {profileData.tutor && (
                        <div className="text-center">
                          <h1 className="text-2xl font-bold mb-2">{profileData.tutor.username}</h1>
                          <p className={`text-lg font-semibold ${profileData.tutor.is_active ? "text-green-500" : "text-red-500"} mb-2`}>
                            {profileData.tutor.is_active ? "Active" : "Not Active"}
                          </p>
                          <div className="flex items-center justify-center mb-2">
                            <span className="font-bold text-lg mr-2">{averageRating.toFixed(1)}</span>
                            <StarRating rating={averageRating} size={20} />
                          </div>
                        </div>
                      )}
                    </div>
                    <hr className="my-6 border-t border-gray-300" />
                    <div className="flex flex-col">
                      <span className="text-gray-800 uppercase font-bold tracking-wider mb-2">
                        Skills
                      </span>
                      <ul>
                        {Array.isArray(skillData) && skillData.map((skill) => (
                          <li key={skill.id} className="mb-2 flex items-center">
                            {skill.skill}
                            <svg className="w-5 h-5 ml-2 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-span-4 sm:col-span-9">
                  <div className="bg-white shadow-xl rounded-lg p-6">
                    <h2 className="text-xl font-bold mb-4">About Tutor</h2>
                    <p className="text-gray-700">
                      {profileData.about || ''}
                    </p>
                  </div>
                  <div className="bg-white shadow-xl rounded-lg p-6 mt-4">
                    <h2 className="text-xl font-bold mb-4">Client Reviews & Ratings</h2>
                    {currentFeedback.length > 0 ? (
                      <>
                        <ul>
                          {currentFeedback.map((feedback, index) => (
                            <li key={index} className="mb-4 border-b pb-4">
                              <div className="flex items-center mb-2">
                                <span className="mr-2 font-semibold">Rating:</span>
                                {[...Array(5)].map((star, i) => (
                                  <svg
                                    key={i}
                                    className={`w-5 h-5 ${i < feedback.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                  </svg>
                                ))}
                              </div>
                              <p className="text-gray-600"><span className="font-semibold">Comment:</span> {feedback.comment}</p>
                              <p className="text-gray-500 text-sm">
                                {feedback.created_at && `Submitted on ${new Date(feedback.created_at).toLocaleDateString()}`}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <Pagination
                          feedbackPerPage={feedbackPerPage}
                          totalFeedback={sortedFeedbackData.length}
                          paginate={paginate}
                          currentPage={currentPage}
                        />
                      </>
                    ) : (
                      <p className="text-gray-500">No feedback available.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TutorView;
