import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserSidebar from './UserSidebar';
import api from '../../../api/axiosConfig';
import { useSelector } from 'react-redux';

function UserBids() {
  const [jobProposals, setJobProposals] = useState([]);
  const [groupedProposals, setGroupedProposals] = useState({});
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchJobProposals = async () => {
      try {
        const jobProposalsResponse = await api.get(`/tutor/user_proposals/`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        });

        // Set job proposals
        setJobProposals(jobProposalsResponse.data);

        // Group proposals by order number
        const grouped = jobProposalsResponse.data.reduce((acc, proposal) => {
          const orderNumber = Number(proposal.order_number); // Ensure order number is treated as a number
          if (!acc[orderNumber]) {
            acc[orderNumber] = [];
          }
          acc[orderNumber].push(proposal);
          return acc;
        }, {});

        // Log the grouped proposals for debugging
        console.log("Grouped Proposals: ", grouped);

        // Sort order numbers in descending order
        const sortedOrderNumbers = Object.keys(grouped)
          .map(Number) // Convert keys to numbers
          .sort((a, b) => b - a); // Sort in descending order

        // Create a sorted object
        const sortedGroupedProposals = {};
        sortedOrderNumbers.forEach((orderNumber) => {
          sortedGroupedProposals[orderNumber] = grouped[orderNumber];
        });

        // Log the sorted grouped proposals
        console.log("Sorted Grouped Proposals: ", sortedGroupedProposals);

        // Set the state with sorted proposals
        setGroupedProposals(sortedGroupedProposals);
      } catch (error) {
        console.error('Error fetching job proposals:', error);
      }
    };

    if (user.user_id) {
      fetchJobProposals();
    }
  }, [user.user_id]);

  const handleViewBids = (orderNumber) => {
    setSelectedOrderNumber(orderNumber);
  };

  return (
    <div className='md:flex'>
      <UserSidebar />
      <div className="ml-0 lg:ml-80 mb-6 lg:w-[50%] xl:w-[50%] 2xl:w-[50%] p-4">
        <h1 className="text-3xl font-bold mb-4">Proposals for Your Jobs</h1>
        {selectedOrderNumber === null ? (
          <div>
            <table className="w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="py-2 px-4 border-r">Order Number</th>
                  <th className="py-2 px-4 border-r">Number of Bids</th>
                  <th className="py-2 px-4 border-r">Actions</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(groupedProposals).map(([orderNumber, proposals]) => (
                  <tr key={orderNumber}>
                    <td className="py-2 px-4 border-r text-center"><b>{orderNumber}</b></td>
                    <td className="py-2 px-4 border-r text-center">{proposals.length}</td>
                    <td className="py-2 px-4 border-r text-center">
                      <button
                        onClick={() => handleViewBids(orderNumber)}
                        className="text-blue-500 hover:underline"
                      >
                        View Bids
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <button
              onClick={() => setSelectedOrderNumber(null)}
              className="mb-4 bg-blue-500 text-white px-4 py-2 rounded"
            >
              Back to Order List
            </button>
            <table className="w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="py-2 px-4 border-r">Order Number</th>
                  <th className="py-2 px-4 border-r">Title</th>
                  <th className="py-2 px-4 border-r">Tutor</th>
                  <th className="py-2 px-4 border-r">Proposal</th>
                  <th className="py-2 px-4 border-r">Actions</th>
                </tr>
              </thead>
              <tbody>
                {groupedProposals[selectedOrderNumber].map((proposal) => (
                  <tr key={proposal.id}>
                    <td className="py-2 px-4 border-r text-center"><b>{proposal.order_number}</b></td>
                    <td className="py-2 px-4 border-r">{proposal.orderTitle}</td>
                    <td className="py-2 px-4 border-r">{proposal.tutor}</td>
                    <td className="py-2 px-4 border-r">{proposal.proposal}</td>
                    <td className="py-2 px-4 border-r">
                      <Link
                        to={`/user/proposal-details/${proposal.id}`}
                        className="text-blue-500 hover:underline"
                      >
                        View Proposal
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserBids;
