// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import formData from './formData';
// import { useSelector } from 'react-redux'; // Import the useSelector hook
// import { toast } from 'react-toastify';
// import { useLocation } from 'react-router-dom';
// import { useEffect } from 'react';
// import { useRef } from 'react';

// import api from '../../../api/axiosConfig';

// const JobForm = ({ jobDetails = null, edit, upload }) => {
//   const navigate = useNavigate();
//   const [currentStep, setCurrentStep] = useState(1);
//   const [measure, setMeasure] = useState("pages");
//   const [uploadedFiles, setUploadedFiles] = useState([]); // New state for uploaded files
//   const fileInputRef = useRef(null);
//   console.log(upload)

//   const [submitting, setSubmitting] = useState(false);

//   const user = useSelector(state => state.user);

//   const [orderDetails, setOrderDetails] = useState({
//     orderTitle: '',
//     subject: '',
//     type: '',
//     service: '',
//     pages: '',
//     citation: '',
//     spacing: '',
//     educationLevel: '',
//     sources: '',
//     language: '',
//     instructions: '',
//     dueDate: '',
//     // Add user information to the orderDetails object
//     userId: user ? user.user_id : null,
//   });

//   const [pages, setPages] = useState(orderDetails.pages || '');
//   const [words, setWords] = useState(orderDetails.pages ? orderDetails.pages * 300 : '');
//   const [spacing, setSpacing] = useState(orderDetails.spacing || 'Double');

//   const handleSpacingChange = (newSpacing) => {
//     setSpacing(newSpacing);
//     if (words) {
//       const newPages = (words / (newSpacing === 'Single' ? 600 : 300)).toFixed(1);
//       setPages(newPages);
//       setOrderDetails({
//         ...orderDetails,
//         spacing: newSpacing,
//         pages: newPages,
//       });
//     }
//   };

//   const handlePagesChange = (e) => {
//     const newPages = e.target.value;
//     setPages(newPages);
//     if (newPages && !isNaN(newPages)) {
//       const roundedPages = parseFloat(newPages).toFixed(1);
//       const newWords = Math.round(roundedPages * (spacing === 'Single' ? 600 : 300));
//       setWords(newWords);
//       setOrderDetails({
//         ...orderDetails,
//         pages: roundedPages,
//       });
//     } else {
//       setWords('');
//       setOrderDetails({
//         ...orderDetails,
//         pages: '',
//       });
//     }
//   };

//   const handleWordsChange = (e) => {
//     const newWords = e.target.value;
//     setWords(newWords);
//     const newPages = (newWords / (spacing === 'Single' ? 600 : 300)).toFixed(1);
//     setPages(newPages);
//     setOrderDetails({
//       ...orderDetails,
//       pages: newPages,
//     });
//   };

//   useEffect(() => {
//     if (jobDetails) {
//       setOrderDetails({
//         ...orderDetails,
//         orderTitle: jobDetails.orderTitle,
//         subject: jobDetails.subject,
//         type: jobDetails.type,
//         service: jobDetails.service,
//         pages: jobDetails.pages,
//         citation: jobDetails.citation,
//         spacing: jobDetails.spacing,
//         educationLevel: jobDetails.educationLevel,
//         sources: jobDetails.sources,
//         language: jobDetails.language,
//         instructions: jobDetails.instructions,
//         dueDate: new Date(jobDetails.dueDate).toISOString().slice(0, 16),
//         files: jobDetails.files,
//       })
//       if(upload){
//         setCurrentStep(4);
//       }
//       setUploadedFiles(jobDetails.files.map((file) => {
//         return {
//           name: file.split('/').pop(),
//         }
//       }))
//     }
//     console.log(uploadedFiles);
//   }, [jobDetails])


//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setOrderDetails({
//       ...orderDetails,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {

//     e.preventDefault();
//     if (edit) {
//       handleEdit();
//       return;
//     }
//     setSubmitting(true);

//     // console.log('Submitting form...', orderDetails);

//     try {
//       // orderDetails.files = uploadedFiles;
//       // console.log('Submitting form...', orderDetails);
//       const form = new FormData();
//       for (const key in orderDetails) {
//         form.append(key, orderDetails[key]);
//       }

//       for (const file of uploadedFiles) {
//         form.append('file', file);
//       }

//       await api.post('/users/job-order/', form, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
//         },
//       });

//       toast.success('Order placed successfully., Writers will start bidding soon! Refresh and go to "Bids" section to view the bids.', { duration: 10000 });
//       navigate('/user/orders');
//     } catch (error) {
//       console.error('Submission Error:', error);

//       toast.error('Error submitting order. Please try again.');
//       setSubmitting(false);
//     }
//   };

//   const checkLoggedIn = () => {

//     handleNextStep();

//   };

//   const handleNextStep = () => {
//     const form = document.querySelector('form');
//     if (!form.checkValidity()) {
//       form.reportValidity();
//       return;
//     }
//     setCurrentStep(currentStep + 1);
//   };

//   const handleFileUpload = (e) => {
//     const newFiles = Array.from(e.target.files);
//     setUploadedFiles([...uploadedFiles, ...newFiles]);
//     e.target.value = null; // Clear the input
//   };

//   const removeUploadedFile = (fileToRemove) => {
//     setUploadedFiles(uploadedFiles.filter(file => file !== fileToRemove));
//   };

//   const handleEdit = () => {
//     setSubmitting(true);
//     const form = new FormData();
//     for (const key in orderDetails) {
//       form.append(key, orderDetails[key]);
//     }


//     for (const file of uploadedFiles) {
//       if (!file.lastModified) {
//         continue;
//       }
//       form.append('file', file);
//     }
//     //log form data
//     for (var pair of form.entries()) {
//       console.log(pair[0] + ', ' + pair[1]);
//     }


//     api.put(`/users/job-order/${jobDetails.id}/`, form, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//         'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
//       },
//     })
//       .then((resp) => {
//         toast.success('Order successfully updated!');
//         navigate('/user/orders');
//       })
//       .catch((err) => {
//         console.error('Error updating order:', err);
//         toast.error('Error updating order. Please try again.');
//         setSubmitting(false);
//       });
//   }

//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen ">
//       <form style={{ boxShadow: '0 0 2px' }} onSubmit={handleSubmit} className="max-w-md w-full p-6 bg-[#f6f6f6] rounded-sm ">
//         {currentStep === 1 && (
//           <>
//             <div className="mb-4">
//               <label htmlFor="orderTitle" className="block text-gray-700 text-sm font-bold mb-2">1. Order Title</label>
//               <input
//                 type="text"
//                 id="orderTitle"
//                 name="orderTitle"
//                 value={orderDetails.orderTitle}
//                 onChange={handleInputChange}
//                 className="w-full px-3 py-2 border "
//                 required
//               />
//             </div>

//             <div className="mb-4">
//               <label htmlFor="subject" className="block text-gray-700 text-sm font-bold mb-2">2. Subject</label>
//               <select
//                 id="subject"
//                 name="subject"
//                 value={orderDetails.subject}
//                 onChange={handleInputChange}
//                 className="w-full px-3 py-2 border "
//                 required
//               >
//                 <option value="">Select Subject</option>
//                 {formData.subjects.map((subject) => (
//                   <option key={subject} value={subject}>
//                     {subject}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             <div className="mb-4">
//               <label htmlFor="type" className="block text-gray-700 text-sm font-bold mb-2">3. Type</label>
//               <select
//                 id="type"
//                 name="type"
//                 value={orderDetails.type}
//                 onChange={handleInputChange}
//                 className="w-full px-3 py-2 border "
//                 required
//               >
//                 <option value="">Select Type</option>
//                 {formData.assignmentTypes.map((type) => (
//                   <option key={type} value={type}>
//                     {type}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             <div className="mb-4">
//               <label htmlFor="service" className="block text-gray-700 text-sm font-bold mb-2">4. Service</label>
//               <select
//                 id="service"
//                 name="service"
//                 value={orderDetails.service}
//                 onChange={handleInputChange}
//                 className="w-full px-3 py-2 border "
//                 required
//               >
//                 <option value="">Select Service</option>
//                 {formData.services.map((service) => (
//                   <option key={service} value={service}>
//                     {service}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </>
//         )}

//         {currentStep === 2 && (
//           <>
//             <div className="mb-4">
//               <label className="block text-gray-700 text-sm font-bold mb-2">1. Pages / Words</label>
//               <div className="flex space-x-2 mb-2">
//                 <div className="w-1/2">
//                   <label htmlFor="pages" className="block text-gray-700 text-xs mb-1">Pages</label>
//                   <input
//                     type="text"
//                     id="pages"
//                     name="pages"
//                     value={pages}
//                     onChange={handlePagesChange}
//                     onBlur={() => {
//                       if (pages && !isNaN(pages)) {
//                         setPages(parseFloat(pages).toFixed(1));
//                       }
//                     }}
//                     className="w-full px-3 py-2 border"
//                     required
//                   />
//                 </div>
//                 <div className="w-1/2">
//                   <label htmlFor="words" className="block text-gray-700 text-xs mb-1">Words</label>
//                   <input
//                     type="number"
//                     id="words"
//                     name="words"
//                     value={words}
//                     onChange={handleWordsChange}
//                     min="0"
//                     className="w-full px-3 py-2 border"
//                     required
//                   />
//                 </div>
//               </div>
//               <div className="flex space-x-2">
//                 <button
//                   type="button"
//                   onClick={() => handleSpacingChange('Single')}
//                   className={`w-1/2 px-3 py-2 border ${spacing === 'Single' ? 'bg-blue-500 text-white' : 'bg-white'}`}
//                 >
//                   Single Spacing
//                 </button>
//                 <button
//                   type="button"
//                   onClick={() => handleSpacingChange('Double')}
//                   className={`w-1/2 px-3 py-2 border ${spacing === 'Double' ? 'bg-blue-500 text-white' : 'bg-white'}`}
//                 >
//                   Double Spacing
//                 </button>
//               </div>
//             </div>

//             <div className="mb-4">
//               <label htmlFor="citation" className="block text-gray-700 text-sm font-bold mb-2">2. Citation</label>
//               <select
//                 id="citation"
//                 name="citation"
//                 value={orderDetails.citation}
//                 onChange={handleInputChange}
//                 className="w-full px-3 py-2 border rounded-md"
//                 required
//               >
//                 <option value="">Select Citation</option>
//                 <option value="APA 6th Edition">APA 6th Edition</option>
//                 <option value="APA 7th Edition">APA 7th Edition</option>
//                 <option value="Chicago">Chicago</option>
//                 <option value="MLA">MLA</option>
//                 <option value="Havard">Havard</option>
//                 <option value="OSCOLA">OSCOLA</option>
//                 <option value="Bluebook">Bluebook</option>
//                 <option value="IEE">IEE</option>
//                 <option value="Vancouver">Vancouver</option>
//                 <option value="MHRA">MHRA</option>
//                 <option value="AMA">AMA</option>
//               </select>
//             </div>

//             <div className="mb-4">
//               <label htmlFor="educationLevel" className="block text-gray-700 text-sm font-bold mb-2">3. Educational Level</label>
//               <select
//                 id="educationLevel"
//                 name="educationLevel"
//                 value={orderDetails.educationLevel}
//                 onChange={handleInputChange}
//                 className="w-full px-3 py-2 border "
//                 required
//               >
//                 <option value="">Select Educational Level</option>
//                 {formData.educationLevels.map((level) => (
//                   <option key={level} value={level}>
//                     {level}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </>
//         )}

//         {currentStep === 3 && (
//           <>
//             <div className="mb-4">
//               <label htmlFor="sources" className="block text-gray-700 text-sm font-bold mb-2">1. No of Sources</label>
//               <input
//                 type="number"
//                 id="sources"
//                 name="sources"
//                 value={orderDetails.sources}
//                 onChange={handleInputChange}
//                 min="0"
//                 max="1000"
//                 className="w-full px-3 py-2 border "
//                 required
//               />
//             </div>

//             <div className="mb-4">
//               <label htmlFor="language" className="block text-gray-700 text-sm font-bold mb-2">2. Language</label>
//               <select
//                 id="language"
//                 name="language"
//                 value={orderDetails.language}
//                 onChange={handleInputChange}
//                 className="w-full px-3 py-2 border "
//                 required
//               >
//                 <option value="">Select Language</option>
//                 {formData.languages.map((language) => (
//                   <option key={language} value={language}>
//                     {language}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             <div className="mb-4">
//               <label htmlFor="instructions" className="block text-gray-700 text-sm font-bold mb-2">3. Instructions</label>
//               <textarea
//                 id="instructions"
//                 name="instructions"
//                 value={orderDetails.instructions}
//                 onChange={handleInputChange}
//                 className="w-full px-3 py-2 border rounded-md"
//                 rows="4"
//                 required
//               />
//             </div>

//             {edit && (
//               <div className="mb-4">
//                 <label className="block text-gray-700 text-sm font-bold mb-2">Current Instructions:</label>
//                 <div 
//                   className="w-full px-3 py-2 border rounded-md bg-gray-100" 
//                   style={{ whiteSpace: 'pre-wrap' }}
//                 >
//                   {jobDetails.instructions}
//                 </div>
//               </div>
//             )}

//             <div className="mb-4">
//               <label htmlFor="dueDate" className="block text-gray-700 text-sm font-bold mb-2">4. Due Date</label>
//               <input
//                 type="datetime-local"
//                 id="dueDate"
//                 name="dueDate"
//                 value={orderDetails.dueDate}
//                 onChange={handleInputChange}
//                 className="w-full px-3 py-2 border "
//                 required
//               />
//             </div>
//           </>
//         )}

//         {currentStep === 4 && (
//           <>
//             <div className="mb-4">
//               <label className="block text-gray-700 text-sm font-bold mb-2">Upload Files</label>
//               <div className="flex flex-col items-center p-4 border-2 border-dashed border-gray-300 rounded-lg">
//                 <input
//                   type="file"
//                   ref={fileInputRef}
//                   onChange={handleFileUpload}
//                   className="hidden"
//                   multiple
//                 />
//                 <button
//                   type="button"
//                   onClick={() => fileInputRef.current.click()}
//                   className="bg-blue-500 text-white px-4 py-2 rounded-md mb-2"
//                 >
//                   Select Files
//                 </button>
//                 <p className="text-sm text-gray-500">or drag and drop files here</p>
//               </div>
//               <div className="mt-4">
//                 {uploadedFiles.map((file, index) => (
//                   <div key={index} className="flex items-center justify-between mb-2 p-2 bg-gray-100 rounded">
//                     <span className="truncate max-w-xs">{file.name}</span>
//                     <button
//                       type="button"
//                       onClick={() => removeUploadedFile(file)}
//                       className="text-red-500 hover:text-red-700"
//                     >
//                       Remove
//                     </button>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </>
//         )}

//         <div className="flex justify-between">
//           {currentStep > 1 && (
//             <button
//               type="button"
//               className="bg-[black] text-white px-4 py-2 rounded-md focus:outline-none focus:shadow-outline-blue"
//               onClick={() => setCurrentStep(currentStep - 1)}
//               disabled={submitting}
//             >
//               Previous
//             </button>
//           )}

//           {currentStep < 4 ? (
//             <input type="button" className="bg-[goldenrod] text-white px-4 py-2 rounded-md focus:outline-none focus:shadow-outline-green" onClick={checkLoggedIn} value={"Next step"} />
//           ) : (
//             <button disabled={submitting} style={submitting == true ? { opacity: 0.2 } : { boxShadow: "0 0 5px" }} type="submit" className="bg-[yellow] text-black px-4 py-2 rounded-md focus:outline-none focus:shadow-outline-indigo">
//               {submitting ? 'Submitting...' : 'Submit'}
//             </button>
//           )}
//         </div>
//       </form>
//     </div>
//   );
// };

// export default JobForm;


import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import formData from './formData';
import { useSelector } from 'react-redux'; // Import the useSelector hook
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import api from '../../../api/axiosConfig';

const JobForm = ({ jobDetails = null, edit, upload }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [measure, setMeasure] = useState("pages");
  const [uploadedFiles, setUploadedFiles] = useState([]); // New state for uploaded files
  const fileInputRef = useRef(null);
  console.log(upload)

  const [submitting, setSubmitting] = useState(false);

  const user = useSelector(state => state.user);

  const [orderDetails, setOrderDetails] = useState({
    orderTitle: '',
    subject: '',
    type: '',
    service: '',
    pages: '',
    citation: '',
    spacing: '',
    educationLevel: '',
    sources: '',
    language: '',
    instructions: '',
    dueDate: '',
    // Add user information to the orderDetails object
    userId: user ? user.user_id : null,
  });

  const [pages, setPages] = useState(orderDetails.pages || '');
  const [words, setWords] = useState(orderDetails.pages ? orderDetails.pages * 300 : '');
  const [spacing, setSpacing] = useState(orderDetails.spacing || 'Double');

  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef();

  const handleSpacingChange = (newSpacing) => {
    setSpacing(newSpacing);
    if (words) {
      const newPages = (words / (newSpacing === 'Single' ? 600 : 300)).toFixed(1);
      setPages(newPages);
      setOrderDetails({
        ...orderDetails,
        spacing: newSpacing,
        pages: newPages,
      });
    }
  };

  const handlePagesChange = (e) => {
    const newPages = e.target.value;
    setPages(newPages);
    if (newPages && !isNaN(newPages)) {
      const roundedPages = parseFloat(newPages).toFixed(1);
      const newWords = Math.round(roundedPages * (spacing === 'Single' ? 600 : 300));
      setWords(newWords);
      setOrderDetails({
        ...orderDetails,
        pages: roundedPages,
      });
    } else {
      setWords('');
      setOrderDetails({
        ...orderDetails,
        pages: '',
      });
    }
  };

  const handleWordsChange = (e) => {
    const newWords = e.target.value;
    setWords(newWords);
    const newPages = (newWords / (spacing === 'Single' ? 600 : 300)).toFixed(1);
    setPages(newPages);
    setOrderDetails({
      ...orderDetails,
      pages: newPages,
    });
  };

  useEffect(() => {
    if (jobDetails) {
      setOrderDetails({
        ...orderDetails,
        orderTitle: jobDetails.orderTitle,
        subject: jobDetails.subject,
        type: jobDetails.type,
        service: jobDetails.service,
        pages: jobDetails.pages,
        citation: jobDetails.citation,
        spacing: jobDetails.spacing,
        educationLevel: jobDetails.educationLevel,
        sources: jobDetails.sources,
        language: jobDetails.language,
        instructions: jobDetails.instructions,
        dueDate: new Date(jobDetails.dueDate).toISOString().slice(0, 16),
        files: jobDetails.files,
      })
      if(upload){
        setCurrentStep(4);
      }
      setUploadedFiles(jobDetails.files.map((file) => {
        return {
          name: file.split('/').pop(),
        }
      }))
    }
    console.log(uploadedFiles);
  }, [jobDetails])


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails({
      ...orderDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (edit) {
      handleEdit();
      return;
    }

    if (!captchaValue) {
      toast.error('Please complete the reCAPTCHA');
      return;
    }

    setSubmitting(true);

    try {
      const form = new FormData();
      for (const key in orderDetails) {
        form.append(key, orderDetails[key]);
      }

      for (const file of uploadedFiles) {
        form.append('file', file);
      }

      form.append('recaptcha', captchaValue);

      await api.post('/users/job-order/', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });

      toast.success('Order placed successfully. Writers will start bidding soon! Refresh and go to "Bids" section to view the bids.', { duration: 10000 });
      navigate('/user/orders');
    } catch (error) {
      console.error('Submission Error:', error);
      toast.error('Error submitting order. Please try again.');
      setSubmitting(false);
    }
  };

  const checkLoggedIn = () => {

    handleNextStep();

  };

  const handleNextStep = () => {
    const form = document.querySelector('form');
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handleFileUpload = (e) => {
    const newFiles = Array.from(e.target.files);
    setUploadedFiles([...uploadedFiles, ...newFiles]);
    e.target.value = null; // Clear the input
  };

  const removeUploadedFile = (fileToRemove) => {
    setUploadedFiles(uploadedFiles.filter(file => file !== fileToRemove));
  };

  const handleEdit = () => {
    setSubmitting(true);
    const form = new FormData();
    for (const key in orderDetails) {
      form.append(key, orderDetails[key]);
    }


    for (const file of uploadedFiles) {
      if (!file.lastModified) {
        continue;
      }
      form.append('file', file);
    }
    //log form data
    for (var pair of form.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }


    api.put(`/users/job-order/${jobDetails.id}/`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
      .then((resp) => {
        toast.success('Order successfully updated!');
        navigate('/user/orders');
      })
      .catch((err) => {
        console.error('Error updating order:', err);
        toast.error('Error updating order. Please try again.');
        setSubmitting(false);
      });
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen ">
      <form style={{ boxShadow: '0 0 2px' }} onSubmit={handleSubmit} className="max-w-md w-full p-6 bg-[#f6f6f6] rounded-sm ">
        {currentStep === 1 && (
          <>
            <div className="mb-4">
              <label htmlFor="orderTitle" className="block text-gray-700 text-sm font-bold mb-2">1. Order Title</label>
              <input
                type="text"
                id="orderTitle"
                name="orderTitle"
                value={orderDetails.orderTitle}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border "
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="subject" className="block text-gray-700 text-sm font-bold mb-2">2. Subject</label>
              <select
                id="subject"
                name="subject"
                value={orderDetails.subject}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border "
                required
              >
                <option value="">Select Subject</option>
                {formData.subjects.map((subject) => (
                  <option key={subject} value={subject}>
                    {subject}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="type" className="block text-gray-700 text-sm font-bold mb-2">3. Type</label>
              <select
                id="type"
                name="type"
                value={orderDetails.type}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border "
                required
              >
                <option value="">Select Type</option>
                {formData.assignmentTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="service" className="block text-gray-700 text-sm font-bold mb-2">4. Service</label>
              <select
                id="service"
                name="service"
                value={orderDetails.service}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border "
                required
              >
                <option value="">Select Service</option>
                {formData.services.map((service) => (
                  <option key={service} value={service}>
                    {service}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">1. Pages / Words</label>
              <div className="flex space-x-2 mb-2">
                <div className="w-1/2">
                  <label htmlFor="pages" className="block text-gray-700 text-xs mb-1">Pages</label>
                  <input
                    type="text"
                    id="pages"
                    name="pages"
                    value={pages}
                    onChange={handlePagesChange}
                    onBlur={() => {
                      if (pages && !isNaN(pages)) {
                        setPages(parseFloat(pages).toFixed(1));
                      }
                    }}
                    className="w-full px-3 py-2 border"
                    required
                  />
                </div>
                <div className="w-1/2">
                  <label htmlFor="words" className="block text-gray-700 text-xs mb-1">Words</label>
                  <input
                    type="number"
                    id="words"
                    name="words"
                    value={words}
                    onChange={handleWordsChange}
                    min="0"
                    className="w-full px-3 py-2 border"
                    required
                  />
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  type="button"
                  onClick={() => handleSpacingChange('Single')}
                  className={`w-1/2 px-3 py-2 border ${spacing === 'Single' ? 'bg-blue-500 text-white' : 'bg-white'}`}
                >
                  Single Spacing
                </button>
                <button
                  type="button"
                  onClick={() => handleSpacingChange('Double')}
                  className={`w-1/2 px-3 py-2 border ${spacing === 'Double' ? 'bg-blue-500 text-white' : 'bg-white'}`}
                >
                  Double Spacing
                </button>
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="citation" className="block text-gray-700 text-sm font-bold mb-2">2. Citation</label>
              <select
                id="citation"
                name="citation"
                value={orderDetails.citation}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md"
                required
              >
                <option value="">Select Citation</option>
                <option value="APA 6th Edition">APA 6th Edition</option>
                <option value="APA 7th Edition">APA 7th Edition</option>
                <option value="Chicago">Chicago</option>
                <option value="MLA">MLA</option>
                <option value="Havard">Havard</option>
                <option value="OSCOLA">OSCOLA</option>
                <option value="Bluebook">Bluebook</option>
                <option value="IEE">IEE</option>
                <option value="Vancouver">Vancouver</option>
                <option value="MHRA">MHRA</option>
                <option value="AMA">AMA</option>
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="educationLevel" className="block text-gray-700 text-sm font-bold mb-2">3. Educational Level</label>
              <select
                id="educationLevel"
                name="educationLevel"
                value={orderDetails.educationLevel}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border "
                required
              >
                <option value="">Select Educational Level</option>
                {formData.educationLevels.map((level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}

        {currentStep === 3 && (
          <>
            <div className="mb-4">
              <label htmlFor="sources" className="block text-gray-700 text-sm font-bold mb-2">1. No of Sources</label>
              <input
                type="number"
                id="sources"
                name="sources"
                value={orderDetails.sources}
                onChange={handleInputChange}
                min="0"
                max="1000"
                className="w-full px-3 py-2 border "
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="language" className="block text-gray-700 text-sm font-bold mb-2">2. Language</label>
              <select
                id="language"
                name="language"
                value={orderDetails.language}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border "
                required
              >
                <option value="">Select Language</option>
                {formData.languages.map((language) => (
                  <option key={language} value={language}>
                    {language}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="instructions" className="block text-gray-700 text-sm font-bold mb-2">3. Instructions</label>
              <textarea
                id="instructions"
                name="instructions"
                value={orderDetails.instructions}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded-md"
                rows="4"
                required
              />
            </div>

            {edit && (
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Current Instructions:</label>
                <div 
                  className="w-full px-3 py-2 border rounded-md bg-gray-100" 
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {jobDetails.instructions}
                </div>
              </div>
            )}

            <div className="mb-4">
              <label htmlFor="dueDate" className="block text-gray-700 text-sm font-bold mb-2">4. Due Date</label>
              <input
                type="datetime-local"
                id="dueDate"
                name="dueDate"
                value={orderDetails.dueDate}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border "
                required
              />
            </div>
          </>
        )}

        {currentStep === 4 && (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Upload Files</label>
              <div className="flex flex-col items-center p-4 border-2 border-dashed border-gray-300 rounded-lg">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  className="hidden"
                  multiple
                />
                <button
                  type="button"
                  onClick={() => fileInputRef.current.click()}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md mb-2"
                >
                  Select Files
                </button>
                <p className="text-sm text-gray-500">or drag and drop files here</p>
              </div>
              <div className="mt-4">
                {uploadedFiles.map((file, index) => (
                  <div key={index} className="flex items-center justify-between mb-2 p-2 bg-gray-100 rounded">
                    <span className="truncate max-w-xs">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => removeUploadedFile(file)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="mb-4">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lcl3WcqAAAAAFkyP0gyUkW3TaDa97pmKCiz69Hc"
                onChange={(value) => setCaptchaValue(value)}
              />
            </div>
          </>
        )}

        <div className="flex justify-between">
          {currentStep > 1 && (
            <button
              type="button"
              className="bg-[black] text-white px-4 py-2 rounded-md focus:outline-none focus:shadow-outline-blue"
              onClick={() => setCurrentStep(currentStep - 1)}
              disabled={submitting}
            >
              Previous
            </button>
          )}

          {currentStep < 4 ? (
            <input type="button" className="bg-[goldenrod] text-white px-4 py-2 rounded-md focus:outline-none focus:shadow-outline-green" onClick={checkLoggedIn} value={"Next step"} />
          ) : (
            <button disabled={submitting} style={submitting == true ? { opacity: 0.2 } : { boxShadow: "0 0 5px" }} type="submit" className="bg-[yellow] text-black px-4 py-2 rounded-md focus:outline-none focus:shadow-outline-indigo">
              {submitting ? 'Submitting...' : 'Submit'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default JobForm;
