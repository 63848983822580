// import React, { useEffect } from 'react';

// const Survey = () => {
//     useEffect(() => {
//         const script = document.createElement('script');
//         script.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd2pEuw6LlDDON80YkLLzdEwOxvpt3vHd9h2FKAqA_2BWJr.js";
//         script.async = true;
//         script.id = "smcx-sdk";
//         document.body.appendChild(script);

//         return () => {
//             document.getElementById('smcx-sdk')?.remove();
//         };
//     }, []);

//     return (
//         <div style={{ padding: '20px' }}>
//             <h1>Feedback Survey</h1>
//             <p>Please take a moment to fill out our survey and provide your valuable feedback.</p>
//             <a style={{ font: '12px Helvetica, sans-serif', color: '#999', textDecoration: 'none' }} href="https://www.surveymonkey.com">
                
//             </a>
//         </div>
//     );
// };

// export default Survey;


import React, { useEffect } from 'react';
import UserSidebar from '../User/UserBoard/UserSidebar';

const Survey = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd2pEuw6LlDDON80YkLLzdEwOxvpt3vHd9h2FKAqA_2BWJr.js";
        script.async = true;
        script.id = "smcx-sdk";
        document.body.appendChild(script);

        return () => {
            document.getElementById('smcx-sdk')?.remove();
        };
    }, []);

    return (
        <div className='md:flex'>
            <UserSidebar />
            <div className="ml-0 lg:ml-80 mb-6 lg:w-[50%] xl:w-[50%] 2xl:w-[50%] p-4">
                <h1 className="text-3xl font-bold mb-4">Feedback Survey</h1>
                <p className="mb-4">Please take a moment to fill out our survey and provide your valuable feedback.</p>
                <a style={{ font: '12px Helvetica, sans-serif', color: '#999', textDecoration: 'none' }} href="https://www.surveymonkey.com">
                    
                </a>
            </div>
        </div>
    );
};

export default Survey;
