// PostJobPage.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import JobForm from './JobForm';
import UserSidebar from './UserSidebar';

const PostJobPage = () => {
  const { state } = useLocation();
  const jobDetails = state ? state.jobDetails : null;
  const upload = state ? state.upload : null;
  const edit = jobDetails ? true : false;

  return (
    <div className="flex flex-col lg:flex-row">
      <UserSidebar />
      <div className="w-full lg:w-[75%] xl:w-[80%] 2xl:w-[85%] px-4 lg:px-8 py-6">
        <h2 className="text-3xl font-bold mb-6 text-center">{edit ? 'Edit Job' : 'Post an Order'}</h2>
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6 text-center">
          <p className="font-bold">Important:</p>
          <p>Ensure to capture your assignment details correctly. The writers will read over them and place bids as soon as you submit.</p>
        </div>
        <JobForm jobDetails={jobDetails} edit={edit} upload={upload} />
      </div>
    </div>
  );
};

export default PostJobPage;
