import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TutorSidebar from '../Layout/TutorSidebar';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../api/axiosConfig';
import Loader from '../Loader';
import UserSidebar from '../User/UserBoard/UserSidebar';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setDisplayChat, setNewOrderMessage } from '../../Redux/store';
import PaymentComponent from '../../features/PaymentComponent';
import { FaFileDownload, FaTrash } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';


// Separate ProposalForm component
const ProposalForm = ({ onSubmit, isSubmitting, priceError,proposal, setProposal, price, setPrice }) => (
  <form
    onSubmit={onSubmit}
    className="absolute mt-6 ml-8 bg-[#f6f6f6] p-4 border border-gray-300 rounded-md"
    style={{ top: '-630%', left: '30%', transform: 'translateX(-50%)', boxShadow: '0 0 10px ' }}
  >
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
        Message to client
        <textarea
          name='proposal'
          value={proposal}
          onChange={(e) => setProposal(e.target.value)}
          rows={6} // Adjust the number of rows as needed
          className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          required
        />
      </label>
    </div>

    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
        Price
        <input
          type='number'
          name='price'
          className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          required
        />
      </label>
    </div>

    <div className="text-red-500 mb-2">{priceError}</div>

    <button
      type="submit"
      className={`${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500'
        } text-white py-2 px-4 rounded-full hover:bg-red-600 focus:outline-none`}
      disabled={isSubmitting}
    >
      {isSubmitting ? 'Submitting...' : 'Submit Proposal'}
    </button>
  </form>
);

const truncateFileName = (fileName, maxLength = 15) => {
  const nameParts = fileName.split('.');
  if (nameParts.length > 1) {
    const extension = nameParts.pop();
    const name = nameParts.join('.');
    if (name.length > maxLength) {
      return `${name.substring(0, maxLength)}...${extension}`;
    }
    return `${name}.${extension}`;
  }
  return fileName.length > maxLength ? `${fileName.substring(0, maxLength)}...` : fileName;
};

const SubmissionFile = ({ file, removeFile, downloadFile, submitted = false }) => {
  const fileName = file.split('/').pop();
  const truncatedFileName = truncateFileName(fileName);

  return (
    <div className="flex flex-row justify-between items-center py-2 mb-4 w-2/3 mx-auto border rounded-lg border-2">
      <div className="flex items-center w-full">
        <img
          src="/download_icon.png" // Path to your download icon
          alt="Download"
          className="w-6 h-6 cursor-pointer mr-2"
          onClick={() => downloadFile(file)}
        />
        <span
          className="text-lg font-bold mb-2 cursor-pointer"
          onClick={() => downloadFile(file)}
        >
          {truncatedFileName}
        </span>
      </div>
      {!submitted && (
        <div
          className="bg-red-500 text-white px-2 flex justify-center items-center rounded-full hover:bg-red-600 focus:outline-none w-fit -translate-y-4"
          onClick={() => removeFile(file)}
        >
          x
        </div>
      )}
    </div>
  );
}


const Upload_files = ({ downloadFile = (s) => { }, job_id, setSubmitStatus, addFiles, status, setStatus }) => {
  const [files, setFiles] = useState([]);
  const [pStatus, setPStatus] = useState("draft");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFiles([...files, file]);
    event.target.value = null;
  }

  const removeFile = (file) => {
    setFiles(files.filter((f) => f.name !== file));
  }

  const handleFileUpload = async () => {
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file);
    }

    formData.append('status', pStatus);
    try {
      const response = await api.post('/users/upload/' + job_id + '/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      addFiles(files.map((file) => file.name));
      setSubmitStatus(response.data.status);
      setFiles([]);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }

  return (
    <div>
      {files.length > 0 && (
        <>
          <p>Files to upload:</p>
          {files.map((file) => (
            <SubmissionFile file={file.name} removeFile={removeFile} downloadFile={downloadFile} />
          ))}
        </>
      )}

      <div className="flex flex-col items-center py-2 mb-4 w-1/2 mx-auto border rounded-lg border-2">
        <label className="block text-sm font-medium text-gray-700">
          Select file to upload
        </label>
        <input
          type='file'
          name='file'
          className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          onChange={handleFileChange}
        />
      </div>
      <div className="flex flex-col items-center py-2 mb-4 w-1/2 mx-auto">
        <label className="block text-sm font-medium text-gray-700">
          Select submission status
        </label>
        <select
          name="status"
          id='submit-status'
          className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
          onChange={(e) => setPStatus(e.target.value)}
          required
        >
          <option value="draft">Draft</option>
          <option value="submitted">Final</option>
        </select>
      </div>
      <div className="w-1/2 flex justify-center mx-auto">
        <button
          className="bg-[goldenrod] text-white px-4 py-1 flex justify-center items-center rounded focus:outline-none w-fit"
          onClick={handleFileUpload}
        >Upload</button>
      </div>
    </div>
  )
}


const Submission = ({ submission_status, submission_files, job_id, is_user }) => {
  const [status, setStatus] = useState(submission_status);
  const [files, setFiles] = useState([...submission_files]);
  const span_style = () => {
    if (status == "submitted") {
      return "text-white bg-green-500 rounded px-4 py-2";
    } else if (status == "draft") {
      return "text-white bg-yellow-500 rounded px-4 py-2";
    } else if (status == "not submitted") {
      return "text-white bg-red-500 rounded px-4 py-2";
    } else if (status == "approved") {
      return "text-white bg-blue-500 rounded px-4 py-2";
    }
    return "text-white bg-red-500 rounded px-4 py-2";
  }

  const downloadFile = async (url) => {
    // Extract filename from the URL
    const filename = url.substring(url.lastIndexOf('/') + 1);

    // Fetch the file
    const response = await fetch(url, { responseType: 'blob' });
    const blob = await response.blob();

    // Create a temporary URL for the blob
    const downloadUrl = URL.createObjectURL(blob);

    // Create an anchor element to trigger download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename; // Use extracted filename
    link.click();

    // Cleanup
    URL.revokeObjectURL(downloadUrl);
  }


  const removeFile = (file) => {
    setFiles(files.filter((f) => f != file));
  }

  const addFiles = (added_files) => {
    console.log("Added files: ", added_files);
    setFiles([...files, ...added_files]);
  }

  const show_upload = () => {
    if (is_user == true) {
      return false;
    }
    if (submission_status == "submitted") {
      return true;
    }
    return true;
  }

return (
    <div className="mt-4">
      <div className='w-full md:w-1/2 mx-auto flex justify-between items-center mb-4'>
        Submission status <span className={span_style()}>{status}</span>
      </div>
      {files.length > 0 && (
        files.map((file) => (
          <SubmissionFile file={file} removeFile={removeFile} downloadFile={downloadFile} submitted={true} />
        ))
      )}
      {show_upload() == true ? (
        <Upload_files job_id={job_id} setSubmitStatus={setStatus} addFiles={addFiles} status={status} setStatus={setStatus} />
      ) : (
        <div></div>
      )}

    </div>
  )
}

const UserActions = ({ userActions, jobDetails, submissionStatus, setUserActions, setJobDetails }) => {
  const [submitting, setSubmitting] = useState(false);
  console.log("User actions: ", userActions)
  const cancel = () => {
    api.post(`/users/job-order/cancel/${jobDetails.id}/`)
      .then((response) => {
        setUserActions([]);
        setJobDetails({ ...response.data });
        toast.success("Job cancelled successfully!");
      })
      .catch((error) => {
        toast.error("Error cancelling request.\nPlease try again!");
      })
  }

  const accept = () => {
    setSubmitting(true);
    api.post(`/users/job-order/complete/`, {
      order_id: jobDetails.id,
    }).then((response) => {
      setUserActions([]);
      delete response.data.submission_files;
      setJobDetails({ ...response.data });
      setSubmitting(false);
      toast.success("Job completed successfully!");
    })
      .catch((error) => {
        toast.error("Error completing request.\nPlease try again!");
        setSubmitting(false);
      })
  }

  if (userActions == null || userActions.length == 0) {
    return (
      <div></div>
    )
  }

  const showAccept = () => {
    if (submissionStatus.toLowerCase() != "submitted") {
      return false;
    }
    return userActions.includes("accept");
  }

  return (
    <div className="flex flex-row justify-around items-center py-2 mb-4 w-full md:w-1/2 mx-auto border rounded-lg border-2 flex-wrap">
      {userActions.includes("edit") == true &&
        <Link
          to={`/user/post-job`}
          state={{ jobDetails: jobDetails }}
        >
          <div
            className="bg-blue-500 text-white px-4 py-1 flex justify-center items-center rounded focus:outline-none w-fit cursor-pointer"
          >Edit</div>
        </Link>}
      {userActions.includes("cancel") == true && <div
        className="bg-red-500 text-white px-4 py-1 flex justify-center items-center rounded focus:outline-none w-fit cursor-pointer mt-4"
        onClick={cancel}
      >Cancel</div>}
      {showAccept() == true && <div
        className="bg-green-500 text-white px-4 py-1 flex justify-center items-center rounded focus:outline-none w-fit cursor-pointer mt-4"
        onClick={submitting == true ? null : accept}
      >
        {submitting ? "Processing..." : "Accept & release payment"}
      </div>}
      {
        userActions.includes("upload files") == true && <Link
          to={`/user/post-job`}
          state={{
            jobDetails: jobDetails,
            upload: true,
          }}
        >
          <div
            className="bg-blue-500 text-white px-4 py-1 flex justify-center items-center rounded focus:outline-none w-fit cursor-pointer mt-4"
          >Upload files</div>
        </Link>

      }
    </div>
  )
}

const JobDetails = () => {
  const [tipAmount, setTipAmount] = useState('');
  const { jobId } = useParams(); // Assuming jobId is being extracted from the URL params
  const { state } = useLocation();
  const [jobDetails, setJobDetails] = useState(null); // Initialize state
  const [from, setFrom] = useState(state?.from);
  const [fetched, setFetched] = useState(false);
  const [submission_files, setSubmissionFiles] = useState([]);
  const [isProposalSubmitted, setProposalSubmitted] = useState(false);
  const [showProposalForm, setShowProposalForm] = useState(false);
  const [priceError, setPriceError] = useState('');
  const user = useSelector(state => state.user);
  console.log("User: ", user.user_type);
  const [propSubmitted, setPropSubmitted] = useState(false);
  const dispatch = useDispatch();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function capitalizeWords(string) {
  // First, replace underscores with spaces
  const stringWithSpaces = string.replace(/_/g, ' ');
    // return string.split(' ').map(capitalizeFirstLetter).join(' ');
    // Then split the string, capitalize each word, and join back
  return stringWithSpaces.split(' ')
    .map(capitalizeFirstLetter)
    .join(' ');
  }

  const [userActions, setUserActions] = useState(state?.userActions);

  const navigate = useNavigate();

  console.log("User actions: ", userActions);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await api.get(`/users/job-order/${jobId}/`); // Ensure this matches the new URL pattern
        setSubmissionFiles(response.data.submission_files);
        delete response.data.submission_files;
        setJobDetails(response.data); // Ensure this is setting the state correctly
        if (response.data.feedback) {
          setRating(response.data.feedback.rating);
          setComment(response.data.feedback.comment);
        }
        setPropSubmitted(user.user_id === response.data.tutor);
      } catch (error) {
        console.error('Error fetching job details:', error);
      }
    };
    if (!fetched) {
      fetchJobDetails().then(() => {
        setFetched(true);
      });
    }
  }, [fetched, jobId, user.user_id]);

  const [proposal, setProposal] = useState({
    price: '',
    proposal: '',
  });

  const proposalSubmitted = () => {
    if (jobDetails.tutor == user.user_id) {
      return true;
    }
    return false;
  }

  const showSub = () => {
    return proposalSubmitted() || user.user_id == jobDetails.user;
  }

  const Sidebar = () => {
    if (user.user_type == "Tutor") {
      return <TutorSidebar />;
    }
    return <UserSidebar />;
  }

  const show_files = () => {
    if (!fetched) {
      return false;
    }
    if (jobDetails.tutor == user.user_id) {
      return true;
    }
    if (jobDetails.user == user.user_id) {
      return true;
    }
    return false;
  }

  const isValidPrice = (value) => /^[1-9]\d*$/.test(value);

  const showTip = () => {
    //show tip if the user is the client and the tutor is not null and order status is not cancelled
    if (user.user_id == jobDetails.user && jobDetails.tutor != null && jobDetails.status != "cancelled") {
      return true;
    }

    return false;
  }

  const handleTip = () => {
    toast.success("Tip sent successfully!");
  }

  const handleProposalSubmission = async (event) => {
    event.preventDefault();

    // Validate the price input to allow only positive integers starting from 1
    if (!isValidPrice(event.target.price.value)) {
      setPriceError('Price must be a positive number.');
      return;
    }

    // Clear any previous price error
    setPriceError('');
    console.log(jobDetails);

    try {
      // Assuming you have an API endpoint to handle proposal submission
      const response = await api.post('/tutor/proposal/', {
        job_id: jobDetails.id,
        username: user.username,
        price: event.target.price.value,
        proposal: event.target.proposal.value,
        isAccepted: false,
      });

      console.log('Proposal submitted:', response.data);

      // Set the flag to indicate that the proposal is submitted
      setProposalSubmitted(true);

      // Redirect to /tutor/bids on successful submission
      navigate('/tutor/bids');
    } catch (error) {
      console.error('Error submitting proposal:', error);
    }
  };

  const downloadFile = async (url) => {
    // Extract filename from the URL
    const filename = url.substring(url.lastIndexOf('/') + 1);

    // Fetch the file
    const response = await fetch(url, { responseType: 'blob' });
    const blob = await response.blob();

    // Create a temporary URL for the blob
    const downloadUrl = URL.createObjectURL(blob);

    // Create an anchor element to trigger download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename; // Use extracted filename
    link.click();

    // Cleanup
    URL.revokeObjectURL(downloadUrl);
  }

  const getDueTime = (dueDate) => {
    const due = new Date(dueDate);
    const now = new Date();
    const diff = due - now;
    
    if (diff < 0) {
      return { text: "Overdue", isOverdue: true };
    }
    
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    
    let text;
    if (days > 0) {
      text = `${days} day${days > 1 ? 's' : ''} and ${hours} hour${hours > 1 ? 's' : ''} remaining`;
    } else if (hours > 0) {
      text = `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minute${minutes > 1 ? 's' : ''} remaining`;
    } else {
      text = `${minutes} minute${minutes > 1 ? 's' : ''} remaining`;
    }
    
    return { text, isOverdue: false };
  }

  const handleAccept = async () => {
    try {
      const response = await api.post(`/users/job-order/complete/`, {
        order_id: jobDetails.id,
      });
      setUserActions([]);
      delete response.data.submission_files;
      setJobDetails({ ...response.data });
      toast.success("Job completed successfully!");
    } catch (error) {
      toast.error("Error completing request. Please try again!");
    }
  };

  const handleCancel = async () => {
    try {
      const response = await api.post(`/users/job-order/cancel/${jobDetails.id}/`);
      setUserActions([]);
      setJobDetails({ ...response.data });
      toast.success("Job cancelled successfully!");
    } catch (error) {
      toast.error("Error cancelling request. Please try again!");
    }
  };

  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [comment, setComment] = useState('');
  const [isEditingFeedback, setIsEditingFeedback] = useState(false);

  const handleSubmitFeedback = async () => {
    try {
      const response = await api.post(`/users/job-order/${jobId}/feedback/`, { rating, comment });
      setJobDetails({ ...jobDetails, feedback: response.data });
      setIsEditingFeedback(false);
      toast.success("Feedback submitted successfully!");
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error("Error submitting feedback. Please try again.");
    }
  };

  const renderStarRating = () => {
    return (
      <div className="flex items-center">
        {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1;
          return (
            <label key={index}>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => setRating(ratingValue)}
                className="hidden"
              />
              <FaStar
                className="cursor-pointer"
                color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                size={24}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(0)}
              />
            </label>
          );
        })}
      </div>
    );
  };

  const renderFeedbackSection = () => {
    if (jobDetails.feedback && jobDetails.feedback.length > 0) {
      return (
        <div className="mb-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Your Feedback</h2>
          <div>
            <div className="flex items-center mb-2">
              <span className="mr-2 font-semibold">Rating:</span>
              {[...Array(5)].map((star, index) => (
                <FaStar key={index} color={index < jobDetails.feedback[0].rating ? "#ffc107" : "#e4e5e9"} size={20} />
              ))}
            </div>
            <p className="text-gray-700"><span className="font-semibold">Comment:</span> {jobDetails.feedback[0].comment}</p>
          </div>
        </div>
      );
    }

    if (user.user_id === jobDetails.user) {
      return (
        <div className="mb-8 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Leave Feedback</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Rating</label>
            {renderStarRating()}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Comment</label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows="4"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
            ></textarea>
          </div>
          <button
            onClick={handleSubmitFeedback}
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none transition duration-300 ease-in-out"
          >
            Submit Feedback
          </button>
        </div>
      );
    }

    return null;
  };

  if (!fetched) {
    return (
      <div>
        <Sidebar />
        <Loader />
      </div>
    )
  }

  const { text: dueText, isOverdue } = getDueTime(jobDetails.dueDate);

  const renderSafeValue = (key, value) => {
    const sensitiveFields = ['tutor_email', 'user_email'];
    if (sensitiveFields.includes(key)) {
      return '********';
    }
    return value;
  };

  if (!jobDetails) {
    return <div>Loading...</div>; // Show a loading state while fetching
  }

  return (
    <div>
      <Sidebar />
      <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%] px-8">
        <h1 className="text-3xl font-bold mb-6">Job Details</h1>
        
        {/* Job Details Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
          {Object.entries(jobDetails).map(([key, value]) => (
            key !== "user_email" && key !== "tutor_email" && key !== "submission_status" && 
            key !== "tutor" && key !== "id" && key !== "feedback" && key !== "instructions" ? (
              <div key={key} className="p-4 bg-gray-100 rounded-md shadow-sm">
                <p className="text-lg font-semibold text-gray-700">
                  {capitalizeWords(key.replace(/([a-z])([A-Z])/g, '$1 $2'))}:
                </p>
                <div className="mt-2">
                  {key === 'files' ? (
                    value && value.length > 0 ? (
                      <ul>
                        {value.map((file, index) => (
                          <li key={index}>
                            <span
                              className="text-blue-500 hover:underline cursor-pointer"
                              onClick={() => downloadFile(file)}
                            >
                              {file.split('/').pop()}
                            </span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-gray-600">No files available</p>
                    )
                  ) : key === 'order_amount' ? (
                    <p className="text-green-500 font-semibold">
                      ${parseFloat(value).toFixed(2)}
                    </p>
                  ) : key === 'tutor_username' && value ? (
                    <Link to={`/user/tutor-view/${jobDetails.tutor}`} className="text-blue-500 hover:underline">
                      {value}
                    </Link>
                  ) : (
                    <p className="text-gray-800">{renderSafeValue(key, value == null ? "No data" : value.toString())}</p>
                  )}
                </div>
              </div>
            ) : null
          ))}
        </div>

        {/* Instructions Section */}
        <div className="mb-8 p-4 bg-gray-100 rounded-md shadow-sm">
          <p className="text-lg font-semibold text-gray-700">Instructions:</p>
{/*           <p className="mt-2 text-gray-800">{jobDetails.instructions}</p> */}
          <pre className="mt-2 text-gray-800 whitespace-pre-wrap font-sans">{jobDetails.instructions}</pre>
        </div>

        {/* Time Remaining Section */}
        <div className="mb-8 p-4 bg-gray-100 rounded-md shadow-sm">
          <p className="text-lg font-semibold text-gray-700">Time Remaining:</p>
          <p className={`mt-2 ${isOverdue ? 'text-red-500' : 'text-green-500'}`}>{dueText}</p>
        </div>

        {/* Uploaded Files Section */}
        {show_files() && (
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Uploaded Files</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {submission_files.map((file, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <FaFileDownload className="text-blue-500" />
                  <span 
                    className="text-sm text-blue-500 hover:underline cursor-pointer"
                    onClick={() => downloadFile(file)}
                  >
                    {truncateFileName(file.split('/').pop(), 20)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* User Actions Section */}
        {user.user_id === jobDetails.user && (
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">User Actions</h2>
            <div className="flex flex-wrap gap-4">
              {userActions.includes("accept") && jobDetails.submission_status.toLowerCase() === "submitted" && (
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none"
                  onClick={() => {
                    if (window.confirm("Are you sure you want to accept and release payment?")) {
                      handleAccept();
                    }
                  }}
                >
                  Accept & Release Payment
                </button>
              )}
              {userActions.includes("upload files") && (
                <Link
                  to={`/user/post-job`}
                  state={{
                    jobDetails: jobDetails,
                    upload: true,
                  }}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
                >
                  Upload More Files
                </Link>
              )}
              {userActions.includes("edit") && (
                <Link
                  to={`/user/post-job`}
                  state={{ jobDetails: jobDetails }}
                  className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 focus:outline-none"
                >
                  Edit Order
                </Link>
              )}
              {userActions.includes("cancel") && (
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none"
                  onClick={() => {
                    if (window.confirm("Are you sure you want to cancel this order? This action is irreversible if done.")) {                      handleCancel();
                    }
                  }}
                >
                  Cancel Order
                </button>
              )}
              <button
                className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600 focus:outline-none"
                onClick={() => {
                  if (window.confirm("Please chat with the tutor for a review. If no improvement, contact support via the chat button on the sidebar.")) {
                    // Add logic for requesting review
                  }
                }}
              >
                Request Review
              </button>
              {showTip() && (
                <div className="flex items-center space-x-2">
                  <input
                    type="number"
                    value={tipAmount}
                    onChange={(e) => setTipAmount(e.target.value)}
                    placeholder="Ener Tip amount (USD)"
                    className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                  />
                  <PaymentComponent
                    country={"US"}
                    currency={"USD"}
                    amount={tipAmount ? parseFloat(tipAmount) : 0}
                    email={user.email}
                    first_name={user.first_name}
                    last_name={user.last_name}
                    redirect_url={window.location.href}
                    publishable={process.env.REACT_APP_PUBLIC}
                    onCompleted={() => toast.success('Transaction was successful!')}
                    onFailed={() => toast.error('Payment failed!')}
                    buttonText={"Tip Writer"}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {/* Feedback Section */}
        {renderFeedbackSection()}

        {/* Messaging Section */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Messaging</h2>
          {user.user_id === jobDetails.user && jobDetails.tutor != null ? (
            <button
              onClick={() => {
                dispatch(setNewOrderMessage({
                  order_number: jobDetails.order_number,
                  email: jobDetails.tutor_email,
                  username: jobDetails.tutor_username
                }));
                dispatch(setDisplayChat('block'));
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Message Tutor
            </button>
          ) : user.user_type === "Tutor" && (
            <button
              onClick={() => {
                dispatch(setNewOrderMessage({
                  order_number: jobDetails.order_number,
                  email: jobDetails.user_email,
                  username: user.username
                }));
                dispatch(setDisplayChat('block'));
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Message Client
            </button>
          )}
        </div>

        {/* Tutor Actions */}
        {user.user_type === "Tutor" && (
          <div className="mb-8">
            <button
              onClick={() => {
                dispatch(setNewOrderMessage({
                  order_number: jobDetails.order_number,
                  email: jobDetails.user_email,
                  username: user.username
                }));
                dispatch(setDisplayChat('block'));
              }}
              className="text-blue-500 underline mb-4"
            >
              Message client
            </button>
            <div className="relative">
              <button
                className={`${proposalSubmitted() || showProposalForm ? 'bg-black' : 'bg-red-500'} text-white py-2 px-4 rounded-full hover:bg-red-600 focus:outline-none`}
                onClick={() => setShowProposalForm(!showProposalForm)}
              >
                {proposalSubmitted() ? 'Bid Submitted' : showProposalForm ? 'Cancel' : 'Submit Bid'}
              </button>
              {showProposalForm && (
                <div className='mt-4 bg-red-500 p-4 rounded-md'>
                  <ProposalForm
                    onSubmit={handleProposalSubmission}
                    isSubmitting={isProposalSubmitted}
                    priceError={priceError}
                  />
                </div>
              )}
            </div>
            {proposalSubmitted() && (
              <p className="mt-4 text-green-600">Proposal submitted!</p>
            )}
            {/* Add file upload functionality for tutors */}
            {jobDetails.tutor === user.user_id && (
              <div className="mt-4">
                <h3 className="text-xl font-bold mb-2">Upload Files</h3>
                <Upload_files
                  job_id={jobDetails.id}
                  setSubmitStatus={(status) => setJobDetails({...jobDetails, submission_status: status})}
                  addFiles={(newFiles) => setSubmissionFiles([...submission_files, ...newFiles])}
                  status={jobDetails.submission_status}
                  setStatus={(status) => setJobDetails({...jobDetails, submission_status: status})}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default JobDetails;
